import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import axios from './axios-api';
import Layout from "./hoc/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";
import Aux1 from "./hoc/Aux1/Aux1";
import * as actions from "./store/actions/index";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import axios from "./axios-api";

//import { DataTable } from "primereact/datatable";
import "react-multi-carousel/lib/styles.css";
import "./common.css";
import "./App.css";
import "./AppEdit.css";
import "./styles/css/responsive.css";
import packageJson from '../package.json';
global.appVersion = packageJson.version;

const Auth = lazy(() => import("./containers/Auth/Auth"));
const Landingpage = lazy(() => import("./components/Landingpage/Landingpage"));
const LandingpageEdit = lazy(() =>
  import("./components/LandingpageEdit/LandingpageEdit")
);
const Locationpage = lazy(() =>
  import("./components/Locationpage/Locationpage")
);
const LocationpageEdit = lazy(() =>
  import("./components/LocationpageEdit/LocationpageEdit")
);
const MainpageEdit = lazy(() =>
  import("./components/MainpageEdit/MainpageEdit")
);
const events = lazy(() => import("./components/events/events"));

const announcements = lazy(() =>
  import("./components/announcements/announcements")
);
const Launch = lazy(() => import("./components/Launch/Launch"));
//const Tags = lazy(() => import('./components/Tags/Tags'));

const windowUrl = window.location.hash;
if (windowUrl.includes("token")) {
  const url = windowUrl.replace("#/?id", "");
  const id = url.substring(url.indexOf("=") + 1, url.lastIndexOf("&"));
  let sp = url.replace(id, "");
  sp = sp.replace("=&token=", "");

  localStorage.setItem("tempuser", id);
  localStorage.setItem("temptoken", sp);
  localStorage.setItem("templink", "/homeedit");
}

// const semverGreaterThan = (versionA, versionB) => {
//   const versionsA = versionA.split(/\./g);

//   const versionsB = versionB.split(/\./g);
//   while (versionsA.length || versionsB.length) {
//     const a = Number(versionsA.shift());

//     const b = Number(versionsB.shift());
//     // eslint-disable-next-line no-continue
//     if (a === b) continue;
//     // eslint-disable-next-line no-restricted-globals
//     return a > b || isNaN(b);
//   }
//   return false;
// };

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Suspense
        fallback={
          <div className="loader h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          </div>
        }
      >
        <Switch>
          {/* <Route path="/" exact component={Landingpage} />
          <Route path="/homeedit" component={LandingpageEdit} />
          <Route path="/MainpageEdit" component={MainpageEdit} />
          <Route path="/Locationpage" component={Locationpage} />
          <Route
            path="/ahmedabad"
            render={() => <LocationpageEdit id={9} name={"Ahmedabad"} />}
          />
          <Route
            path="/burhanpur"
            render={() => <LocationpageEdit id={10} />}
          />
          <Route
            path="/taherabad"
            render={() => <LocationpageEdit id={11} />}
          />
          <Redirect to="/" /> */}
          <Route path="/auth" component={Auth} />
          <Route path="/" exact component={Auth} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Suspense
          fallback={
            <div className="loader h-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            <Route path="/" exact component={Launch} />
            <Route path="/homeedit" component={LandingpageEdit} />
            <Route path="/mumineenedit" component={MainpageEdit} />
            <Route path="/events" component={events} />
            <Route path="/announcements" component={announcements} />
            {/* <Route path="/ahmedabad1" component={Locationpage} /> */}
            {/* <Route
              path="/ahmedabad"
              render={() => <LocationpageEdit id={9} name={"Ahmedabad"} />}
            /> */}
            {}
            {JSON.parse(localStorage.getItem("locations")) != null &&
            JSON.parse(localStorage.getItem("locations")).length > 0
              ? JSON.parse(localStorage.getItem("locations")).map((items) => (
                  <Route
                    path={items.url}
                    render={() => (
                      <LocationpageEdit
                        id={items.mazarid}
                        name={items.mazarname}
                      />
                    )}
                  />
                ))
              : null}

            <Route path="/logout" component={Logout} />

            <Route
              path="**"
              render={() => (
                <article>
                  <h1 className="text-danger">No Route Configured!</h1>
                  <h4 className="text-danger">
                    Please check your Route Configuration
                  </h4>
                </article>
              )}
            />
          </Switch>
        </Suspense>
      );
    }

    return (
      <Aux1>
        <CacheBuster
          currentVersion={version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          loadingComponent={
            <div className="loader h-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </div>
          } //If not pass, nothing appears at the time of new version check.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          <div className="App">
                <Layout>{routes}</Layout>
              </div>
        </CacheBuster>
        {/* <CacheBuster>
          {() => {
            fetch(`/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
              .then((response) => response.json())
              .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                global.metaVersion = latestVersion;

                if (latestVersion !== currentVersion) {
                  const shouldForceRefresh = semverGreaterThan(
                    latestVersion,
                    currentVersion
                  );

                  if (shouldForceRefresh) {
                    console.log(
                      `We have a new version - ${latestVersion}. Should force refresh`
                    );
                    console.log("Clearing cache and hard re.");

                    if (window.caches !== undefined) {
                      caches.keys().then(async function (names) {
                        await Promise.all(
                          names.map((name) => caches.delete(name))
                        );
                      });
                    }

                    // delete browser cache and hard reload
                    localStorage.removeItem("token");
                    localStorage.removeItem("expirationDate");
                    localStorage.removeItem("userId");
                    localStorage.removeItem("role");
                    localStorage.removeItem("role2");
                    localStorage.removeItem("username");
                    localStorage.removeItem("khidmatguzar");
                    localStorage.removeItem("link");
                    localStorage.removeItem("photo");
                    localStorage.removeItem("temptoken");
                    localStorage.removeItem("tempuser");
                    localStorage.removeItem("locations");
                    window.location.reload(true);
                  } else {
                    console.log(
                      `You already have the latest version - ${latestVersion}. No cache refresh needed.`
                    );
                  }
                }
              });

            return (
              <div className="App">
                <Layout>{routes}</Layout>
              </div>
            );
          }}
        </CacheBuster> */}
      </Aux1>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
