import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./index.css";
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import authReducer from './store/reducers/auth';
import navigationReducer from './store/reducers/navigation';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const rootReducer = combineReducers({
    Navigation:navigationReducer,
    auth: authReducer
});

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <HashRouter>
        {toast.configure({
                    position:"top-center",
                    autoClose:2500,
                    hideProgressBar:true,
                    newestOnTop:true,
                    transition:Zoom,
                    rtl:false,
                    })}
            <App />
        </HashRouter>
    </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
registerServiceWorker();
