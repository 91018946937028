import React, { Component } from "react";
import { connect } from "react-redux";

import Aux1 from "../Aux1/Aux1";
import Login from '../../containers/Auth/Auth';

//import classes from "./Layout.css";

// import '../../styles/css/bootstrap-datetimepicker.min.css';
// import '../../styles/css/fontawesome-all.min.css';
// import '../../styles/css/fonts.css';
// import '../../styles/css/jquery.mCustomScrollbar.min.css';

// import '../../styles/css/customScroll.css';
// import "react-datepicker/dist/react-datepicker.css";
// import '../../styles/css/tooltip.css';
// import '../../styles/css/utility.css';
// import '../../styles/css/style.css';
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";

//import '../../styles/css/responsive.css';



//import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
//import LeftNavigation from "../../components/Navigation/LeftNavigation/LeftNavigation";
//import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
//import TopHeader from '../../components/Navigation/TopHeader/TopHeader';
//import Login from '../../containers/Auth/Auth';
//import SSO from '../../containers/Auth/SSO';
//import Footer from '../../components/footer/footer';
//import PopUp from '../../components/PopUp/PopUp';
import '../../assets/stylesheets/calendar.css';

class Layout extends Component {
  state = {
    showSideDrawer: false
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState(prevState => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    //if (this.props.isAuthenticated && this.props.children._self.props.location.pathname!="/") {
      if (this.props.isAuthenticated){
      return ( 
        <Aux1>
          {/* <LeftNavigation
            isAuth={this.props.isAuthenticated}
          /> */}
         
            {this.props.children}
          {/* <Footer/> */}
        </Aux1>
      
      );
    }
    
    else {
        return (
            <Aux1>
              {/* {this.props.children} */}
              <Login></Login>
              {/* <SSO></SSO> */}
            </Aux1>
          );
    }
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

export default connect(mapStateToProps)(Layout);
